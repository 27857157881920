import bg1 from "../src/assets/images/bg/removeBgImg.svg";
import bg2 from "../src/assets/images/landingpage/addImageCategories.svg";
import bg3 from "../src/assets/images/landingpage/addedImageBgCategories.svg";
import bg4 from "../src/assets/images/landingpage/removeImageCategories.svg";
import bg5 from "../src/assets/images/landingpage/addedBackground.svg";
import bg6 from "../src/assets/images/landingpage/Api&tools.svg";
import cercle from "../src/assets/images/landingpage/cercle.png";
import BackgroundRemover from "../src/components/landingpage/SectionOne";
import Sectiontwo from "../src/components/landingpage/SectionTwo";
import PricingSection from "../src/components/landingpage/PricingSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import {
  getallsubscriptions,
  pricinglist,
} from "../src/Redux/Subscription/SubscriptionSlice";
import NewsLetter from "../src/components/dashboard/newsletter";
import Image from "next/image";
import BlogSection from "../src/components/landingpage/BlogSection";
import Head from "next/head";

const Home = () => {
  const images = [
    { url: bg2, label: "Original" },
    { url: bg3, label: "Background removed" },
    { url: bg4, label: "New background" },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(getallsubscriptions());
    };
  }, [dispatch]);
  const subscriptions = useSelector(pricinglist);

  const sectionRefs = useRef([]);
  useEffect(() => {
    const currentSections = sectionRefs.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    currentSections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      currentSections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Pixeloop | AI-Powered Image Editing Tools",
    description:
      "Enhance your images with AI-powered tools like background removal and addition. Explore our pricing plans and get started today.",
    url: process.env.NEXT_PUBLIC_BASE_URL + "/",
    image: BASE_URL + "/images/LandingPage/remove-image-categories.svg",
    author: {
      "@type": "Organization",
      name: "Pixeloop",
      url: BASE_URL,
    },
    publisher: {
      "@type": "Organization",
      name: "Pixeloop",
      logo: {
        "@type": "ImageObject",
        url: BASE_URL + "/images/Logo/pixeloop-logo.svg",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": BASE_URL + "/",
    },
    potentialAction: {
      "@type": "Action",
      name: "Explore AI Tools",
      target: {
        "@type": "EntryPoint",
        urlTemplate: BASE_URL + "/tools",
      },
    },
    offers: {
      "@type": "Offer",
      name: "Pricing Plans",
      description:
        "Explore affordable pricing plans for all our AI-powered image editing tools.",
      url: BASE_URL + "/tarifs",
      priceCurrency: "TND",
      offers: [
        {
          "@type": "Offer",
          price: "0.00",
          priceCurrency: "TND",
          description: "Free Plan - Try our ai model.",
        },
        {
          "@type": "Offer",
          price: "39.99",
          priceCurrency: "TND",
          description: "Basic Plan - Ideal for small image editing needs.",
        },
        {
          "@type": "Offer",
          price: "69.99",
          priceCurrency: "TND",
          description:
            "Gold Plan - Best for professionals with higher editing requirements.",
        },
        {
          "@type": "Offer",
          priceCurrency: "TND",
          description:
            "Enterprise Plan - Perfect for large teams and organizations.",
        },
      ],
    },
  };

  return (
    <>
      <Head>
        {/* JSON-LD for structured data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <div className="homepage-background">
        <div className="homepage-background-content"></div>
      </div>
      <div className="indexpage">
        <div ref={(el) => (sectionRefs.current[0] = el)} className="section">
          <BackgroundRemover
            title={
              <div className="inline-title">
                <span>
                  Instantly Enhance Your Images{" "}
                  <span className="text-style">
                    with AI
                    <span className="responsive-circle">
                      <Image src={cercle} alt="Cercle" />
                    </span>
                  </span>
                </span>
              </div>
            }
            description={
              <>
                Pixeloop delivers flawless results—remove backgrounds, add
                custom scenes, resize, and more, all without design skills or
                high-end gear. Perfect for elevating your online store, social
                media, and more, while maintaining top quality.
              </>
            }
            buttontext="Remove Background Now "
            imageUrl={bg1}
            imgPosition="right"
            titleColor="#000000"
            buttonColor="primary"
            buttonBgColor="transparent"
            btnUrl="/remove-background"
          />
        </div>
        <div className="home-container">
          <div ref={(el) => (sectionRefs.current[1] = el)} className="section">
            <BackgroundRemover
              title="Add the Perfect Background"
              description="Looking for a unique look? Add a custom background to give your images character.
             Whether it’s a professional setting or a creative scene, our tool offers complete flexibility to personalize every detail."
              buttontext="Add Background Now"
              imageUrl={bg5}
              imgPosition="left"
              titleColor="#000000"
              buttonColor="primary"
              buttonBgColor="transparent"
              btnUrl="/add-background"
            />
          </div>
          <div ref={(el) => (sectionRefs.current[2] = el)} className="section">
            <Sectiontwo
              title={
                <>
                  {" "}
                  <span className="inline-text"> Just </span>{" "}
                  <span className="text-style">
                    imagine!
                    <span className="responsive-circle responsive-circle-100">
                      <Image src={cercle} alt="Cercle" />
                    </span>
                  </span>
                </>
              }
              images={images}
              description="Our online background remover instantly identifies the subject in 
                   any photo, delivering a smooth and precise cutout"
            />
          </div>
          <div ref={(el) => (sectionRefs.current[3] = el)} className="section">
            <BackgroundRemover
              title="Unlock Seamless Integration"
              description={
                <>
                  Pixeloop is your gateway to effortless integration with any
                  eCommerce platform. With powerful APIs and plugins, connect
                  Pixeloop to your system easily, enhancing services with
                  minimal effort. Enjoy robust functionality, flexibility, and a
                  smooth integration that unlocks new business possibilities.
                  Elevate your platform with Pixeloop.
                </>
              }
              buttontext="Try Now Our Integration"
              imageUrl={bg6}
              imgPosition="right"
              titleColor="#00000"
              buttonColor="primary"
              buttonBgColor="transparent"
              btnUrl="/remove-background"
            />
          </div>
          <div ref={(el) => (sectionRefs.current[4] = el)} className="section">
            <PricingSection
              title={
                <>
                  {" "}
                  <span>
                    Our Pricing{" "}
                    <span className="text-style">
                      Packages
                      <span className="responsive-circle my-3">
                        <Image src={cercle} alt="Cercle" />
                      </span>
                    </span>
                  </span>
                </>
              }
              description="At Pixeloop, we offer flexible packages tailored to your needs and budget. 
            No matter your goals, we ensure the best experience for you."
              pricingPlans={subscriptions}
            />
          </div>

          <div ref={(el) => (sectionRefs.current[5] = el)} className="section">
            <BlogSection
              title={
                <>
                  {" "}
                  <span className="inline-text">Our </span>{" "}
                  <span className="text-style">
                    blogs
                    <span className="responsive-circle responsive-circle-50 ">
                      <Image src={cercle} alt="Cercle" />
                    </span>
                  </span>
                </>
              }
              images={images}
              description={
                <>
                  Stay ahead in the game of digital creativity by following our
                  blog for expert insights,<br></br> innovative techniques, and
                  success stories using our AI background removal tools.
                </>
              }
            />
          </div>
        </div>

        <div
          ref={(el) => (sectionRefs.current[6] = el)}
          className="section d-flex justify-content-center newslater-blog"
        >
          <NewsLetter />
        </div>
      </div>
    </>
  );
};
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

Home.seo = {
  title:
    "Add, Remove, and Resize Backgrounds | AI-Powered Image Editing Tool - pixeloop.io",
  description:
    "Easily add, remove, or resize backgrounds with our AI-powered image editing tool. Get fast and professional results with no technical skills required. Perfect for businesses, designers, and developers.",
  additionalMetaTags: [
    {
      name: "keywords",
      content:
        "add background, remove background, resize background,background remover, AI background editing, image editing, bg tool, remove bg, add bg, resize bg, background image tool, background remover tool,background removal, AI background removal,AI bg removal",
    },
    {
      name: "robots",
      content: "index,follow",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content:
        "Add, Remove, and Resize Backgrounds with AI | Image Editing Tool - pixeloop.io",
    },
    {
      name: "twitter:description",
      content:
        "Add, remove, and resize backgrounds in seconds with our AI-powered image editing tool. Get professional results with no technical expertise.",
    },
    {
      name: "twitter:image",
      content: BASE_URL + "/images/LandingPage/remove-bg-tool.svg",
    },

    {
      name: "canonical",
      content: BASE_URL + "/",
    },
  ],
  openGraph: {
    type: "website",
    description:
      "Easily add, remove, or resize backgrounds with our AI-powered image editing tool. Get fast and professional results with no technical skills required. Perfect for businesses, designers, and developers.",
    url: BASE_URL,
    title:
      "Add, Remove, and Resize Backgrounds | AI-Powered Image Editing Tool - pixeloop.io",
    images: [
      {
        url: `${BASE_URL}/images/LandingPage/remove-bg-tool.svg`,
        alt: "Pixeloop - Remove image",
      },
      {
        url: `${BASE_URL}/images/LandingPage/add-background.svg`,
        alt: "Pixeloop - Add background",
      },

      {
        url: `${BASE_URL}/images/LandingPage/add-image-background-categories.svg`,
        alt: "Pixeloop - Resize Image Categories",
      },
      {
        url: `${BASE_URL}/images/LandingPage/add-image-categories.svg`,
        alt: "Pixeloop - Add Image Categories",
      },
      {
        url: `${BASE_URL}/images/LandingPage/remove-image-categories.svg`,
        alt: "Pixeloop - Remove Image Categories",
      },

      {
        url: `${BASE_URL}/images/LandingPage/api-tools.svg`,
        alt: "Pixeloop - Api and tools",
      },
      {
        url: `${BASE_URL}/images/Logo/pixeloop-logo.svg`,
        alt: "Pixeloop - logo",
      },
      {
        url: `${BASE_URL}/images/Logo/pixeloop-white-logo.svg`,
        alt: "Pixeloop - white logo",
      },
    ],
  },
};
export default Home;
