// src/components/ShowMgsInfo.js
import React from "react";
import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../Redux/alert/alertSlice";

const ShowMgsInfo = () => {
  const dispatch = useDispatch();
  const { visible, message, color,timeout=3000 } = useSelector((state) => state.alert);

  if (!visible) return null;
  setTimeout(() => {
    dispatch(hideAlert());
  }, timeout);
  return (
    <Alert color={color} isOpen={visible} toggle={() => dispatch(hideAlert())}>
      {message}
    </Alert>
  );
};

export default ShowMgsInfo;
