import Image from "next/image";

const SectionOne = ({
  title,
  description,
  buttontext,
  imageUrl,
  titleColor,
  buttonColor,
  buttonBgColor,
  imgPosition,
  bgcolor,
  btnUrl,
}) => {
  const titleStyle = titleColor.includes("linear-gradient")
    ? {
        background: titleColor,
        WebkitBackgroundClip: "text",
        color: "transparent",
      }
    : { color: titleColor };

  return (
    <div
      style={{ background: bgcolor }}
      className={`sectionOne   ${
        imgPosition === "left" ? "image-left" : "image-right"
      }`}
    >
      <div className="text-content d-flex flex-column justify-content-between h-100">
        <div>
          {" "}
          <h2 style={titleStyle}>{title}</h2>
          <p>{description}</p>
        </div>
        {buttontext && btnUrl && (
          <div className="btn-container">
            <a href={btnUrl}>
              <button
                className="btn"
                style={{ color: buttonColor, backgroundColor: buttonBgColor }}
              >
                {buttontext}
              </button>
            </a>
          </div>
        )}
      </div>
      {imageUrl && (
        <div className="image-content">
          <Image src={imageUrl} alt="section image" className="section-img-fluid" loading="lazy"/>
        </div>
      )}
    </div>
  );
};

export default SectionOne;
