import Image from "next/image";
import React from "react";

import newsLetterImg from "../../assets/images/landingpage/newsletter.svg";

const NewsLetter = () => {
  return (
    <div className="newsletter-container">
      <div className="newsletter-sub">
        <div className="newsletter-img-desc">
          <div className="w-100 ">
            <Image alt="newsletter" src={newsLetterImg} />
          </div>
          <div className="d-flex flex-column ml-4">
            <span className="newsletter-header">Get our weekly</span>
            <span className="newsletter-title">NEWSLETTER</span>
            <p className="newsletter-desc">
              Join our newsletter to receive exclusive updates, tips, and
              tutorials on maximizing the potential of AI background removal for
              your creative projects
            </p>
            <span className="newsletter-subscribe">Subscribe now!</span>
          </div>
        </div>
        <div className="d-flex align-items-stretch">
          <input
            type={"text"}
            name={"newsletter"}
            id={"newsletter"}
            value={""}
            //onChange={handleChange}
            placeholder={"Email adress"}
            className={"form-input"}
          />{" "}
          <button className="btn newsletter-button ">{"Subscribe"}</button>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
