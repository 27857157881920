import { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import CardComponent from "../Card";
import { useDispatch, useSelector } from "react-redux";
import { bloglist, getallblogs } from "../../Redux/Blog/BlogSlice";
import bookmark from "../../assets/images/icons/bookmark.png";
import Link from "next/link";

const BlogSection = ({ title, description }) => {
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const labelsRef = useRef([]);
  const blogs = useSelector(bloglist);
  const API_URL = process.env.NEXT_PUBLIC_API_URL;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  useEffect(() => {
    dispatch(getallblogs({ per_page: 4, page: 1 }));
  }, [dispatch]);
  useEffect(() => {
    const currentTitleRef = currentTitleRef;
    const currentLabelsRef = [...labelsRef.current];
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentTitleRef) {
      observer.observe(currentTitleRef);
    }
    currentLabelsRef.forEach((label) => {
      if (label) {
        observer.observe(label);
      }
    });

    return () => {
      if (currentTitleRef) {
        observer.unobserve(currentTitleRef);
      }
      currentLabelsRef.forEach((label) => {
        if (label) {
          observer.unobserve(label);
        }
      });
    };
  }, []);

  return (
    <div className="blog-card-section">
      <h2
        ref={titleRef}
        className="card-section-title section-blog-card-title-animated"
      >
        {" "}
        {title}{" "}
      </h2>
      <p className="blog-card-section-description-style">{description}</p>
      <div className="blog-section">
        {typeof window !== "undefined" && window.innerWidth <= 500 ? (
          <Swiper spaceBetween={10} slidesPerView={1.2}>
            {blogs?.map((blog, index) => (
              <SwiperSlide key={index} >
                <Link
                  className=""
                  href={`/blog-details/${blog.slug}`}
                  key={index}
                  passHref
                >
                  <div key={index} className="card-component">
                    <CardComponent
                      title={blog?.title}
                      description={blog?.description}
                      imageUrl={API_URL + "/" + blog?.image_url}
                      iconImg={bookmark}
                      date={formatDate(blog?.creation_date)}
                    />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="blogs-card-container d-flex flex-wrap justify-content-center">
            {blogs?.map((blog, index) => (
              <Link
                className=""
                href={`/blog-details/${blog.slug}`}
                key={index}
                passHref
              >
                <div key={index} className="blog-card-wrapper">
                  <CardComponent
                    title={blog?.title}
                    description={blog?.description}
                    imageUrl={API_URL + "/" + blog?.image_url}
                    iconImg={bookmark}
                    date={formatDate(blog?.creation_date)}
                  />
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogSection;
